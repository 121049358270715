<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    width="800"
    class=""
  >
    <div class="bg-white poppins">
      <div class="modal-header py-3 align-center">
        <h4 class="mb-0 font-weight-bolder poppins">Add Comment</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="20">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5">
        <!--begin::Body-->

        <div
          v-for="(item, i) in other.filter(
            (data) => data.title === 'Comment History'
          )"
          :key="i"
        >
          <v-card class="elevation-0">
            <v-card-title>Comments</v-card-title>
            <v-card-text>
              <v-simple-table height="300" fixed-header>
                <template v-slot:default>
                  <thead>
                    <th class="" v-for="(head, i) in item.data.header" :key="i">
                      {{ head }}
                    </th>
                  </thead>
                  <tbody v-if="item.data.body.length > 0">
                    <tr v-for="(data, index) in item.data.body" :key="index">
                      <td v-for="(el, j) in data" :key="j">{{ el }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <div>No Data Available!</div>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </div>
        <div class="mt-4">
          <div class="h-75px">
            <v-text-field
              v-model="formData.comment"
              outlined
              dense
              label="Comment"
              clearable
              placeholder="Add comment text"
              :error-messages="commentErrors"
              @input="$v.formData.comment.$touch()"
              @blur="$v.formData.comment.$touch()"
            />
          </div>
          <div class="my-4 d-flex justify-center">
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="addCommentHandleFunction"
            >
              Add comment
            </button>
          </div>
        </div>

        <!--end::Body-->
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
export default {
  mixins: [validationMixin],
  name: "AddComment",
  validations: {
    formData: {
      comment: { required },
    },
  },
  data: () => ({
    id: null,
    dialog: false,
    serverData: [],
    formData: {
      comment: null,
    },
  }),
  computed: {
    commentErrors() {
      return this.handleFormValidation("comment", this);
    },
    other() {
      return this.serverData;
    },
  },
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    setId(id) {
      this.id = id;
    },
    loadDetailData() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = { id: this.id };
      ApiService.post("/warehouse_management/warehouse_exceptions/show", data)
        .then((response) => {
          this.$store.commit(SET_PAGE_LOADING, false);
          this.serverData = response.data.data;
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    toggleModal() {
      if (this.dialog) {
        this.serverData = [];
        this.id = null;
        this.formData.comment = null;
        this.dialog = !this.dialog;
      } else {
        this.dialog = !this.dialog;
        this.loadDetailData();
      }
    },
    addCommentHandleFunction() {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post(
        "/warehouse_management/warehouse_exceptions/add-comment",
        { id: this.id, comment: this.formData.comment }
      )
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
          this.formData.comment = null;
          this.loadDetailData();
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
          this.toggleModal();
        });
    },
  },
};
</script>

<!--<style scoped>-->
<!--div {-->
<!--  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;-->
<!--  font-size: 15px;-->
<!--}-->
<!--</style>-->
