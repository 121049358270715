<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="isDataLoadedFromServer"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Add Exception</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <div class="row">
          <!--begin::Body-->
          <div class="h-75px col-12 col-sm-6 col-md-4">
            <v-select
              v-model="formData.client_id"
              label="Customer"
              item-text="text"
              item-value="index"
              :items="serverData.customers"
              clearable
              outlined
              dense
              required
              :error-messages="client_idErrors"
              @input="$v.formData.client_id.$touch()"
              @blur="$v.formData.client_id.$touch()"
            ></v-select>
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-4">
            <v-select
              v-model="formData.related_type"
              label="Related Type"
              item-text="text"
              item-value="index"
              :items="serverData.related_types"
              clearable
              outlined
              dense
              :error-messages="related_typeErrors"
              @input="$v.formData.related_type.$touch()"
              @blur="$v.formData.related_type.$touch()"
            ></v-select>
          </div>
          <div class="h-75px col-12 col-sm-6 col-md-4">
            <v-select
              v-model="formData.exception_type_id"
              label="Exception Type"
              item-text="text"
              item-value="index"
              :items="exception_type_values"
              clearable
              outlined
              dense
              :disabled="!formData.related_type"
              :error-messages="exception_type_idErrors"
              @input="$v.formData.exception_type_id.$touch()"
              @blur="$v.formData.exception_type_id.$touch()"
            ></v-select>
          </div>
          <div class="h-75px col-12 col-sm-6 col-md-4">
            <v-text-field
              v-model="formData.reference_no"
              label="Reference Number"
              clearable
              outlined
              dense
              :error-messages="reference_noErrors"
              @input="$v.formData.reference_no.$touch()"
              @blur="$v.formData.reference_no.$touch()"
            ></v-text-field>
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-4">
            <v-select
              v-model="formData.warehouse_id"
              label="Warehouse"
              item-text="text"
              item-value="index"
              :items="serverData.warehouses"
              clearable
              outlined
              dense
              :error-messages="warehouse_idErrors"
              @input="$v.formData.warehouse_id.$touch()"
              @blur="$v.formData.warehouse_id.$touch()"
            ></v-select>
          </div>
          <div class="h-75px col-12 col-sm-6 col-md-4">
            <v-select
              v-model="formData.status"
              label="Status"
              item-text="text"
              item-value="index"
              :items="serverData.statuses"
              clearable
              outlined
              dense
              disabled
            ></v-select>
          </div>

          <div class="h-75px col-12">
            <v-text-field
              v-model="formData.resolution"
              label="Resolution"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
        </div>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      warehouse_id: { required },
      related_type: { required },
      // related_id: { required },
      client_id: { required },
      reference_no: { required },
      exception_type_id: { required },
    },
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      warehouse_id: null,
      related_type: null,
      // related_id: null,
      client_id: null,
      reference_no: null,
      exception_type_id: null,
      resolution: null,
      status: "open",
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/warehouse_management/warehouse_exceptions/create")
        .then((response) => {
          this.serverData = response.data;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    toggleModal() {
      if (this.dialog) this.resetCreateForm();
      else this.loadDataFromServer();

      this.dialog = !this.dialog;
    },
    submitCreateForm() {
      this.$v.$touch();
      this.pageLoader(true);
      let data = this.formData;
      ApiService.post("/warehouse_management/warehouse_exceptions/store", data)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: `Record has been successfully added!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        warehouse_id: null,
        related_type: null,
        // related_id: null,
        client_id: null,
        reference_no: null,
        exception_type_id: null,
        resolution: null,
        status: "open",
      };
    },
  },
  computed: {
    exception_type_values() {
      let values = [];
      if (this.formData.related_type) {
        values = this.serverData.exception_type_values.filter(
          (value) => value.type == this.formData.related_type
        );
      }
      return values;
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    warehouse_idErrors() {
      return this.handleFormValidation("warehouse_id", this);
    },
    related_typeErrors() {
      return this.handleFormValidation("related_type", this);
    },
    // related_idErrors() {
    //   return this.handleFormValidation("related_id", this);
    // },
    client_idErrors() {
      return this.handleFormValidation("client_id", this);
    },
    reference_noErrors() {
      return this.handleFormValidation("reference_no", this);
    },
    exception_type_idErrors() {
      return this.handleFormValidation("exception_type_id", this);
    },
  },
};
</script>
